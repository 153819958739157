import colors from "../../colors";
import styled from "styled-components";
import { Input as AntInput } from "formik-antd";

const Input = styled(AntInput)`
  background-color: ${colors.white};
  border: solid 1px ${colors.grey};
  /* background-color  :${colors.primary}; */
  /* border  :solid 1px ${colors.primaryDark}; */
  color: white;
  font-size: 15px;
  line-height: 1.1;
  font-weight: 400px;
  &:focus {
    /* border-color :${colors.primaryDark};
    background-color :${colors.primary}; */
  }
  .ant-input {
    padding: 7px;
  }
  .ant-input-disabled {
    /* background-color  :${colors.primaryDark} !important;
    border  :solid 1px ${colors.primaryDark} !important; */
  }
  input {
    /* border-color :${colors.primaryDark};
    background-color: ${colors.primary}; */
  }
`;

export default Input;
