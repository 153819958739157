import React from "react";
import { Form, FormItem, Input } from "formik-antd";
import { Input as AntInput, Button, Form as AntForm, message } from "antd";
import { formItemLayout, tailFormItemLayout } from "../../utils";
import { CopyOutlined } from "@ant-design/icons";
import { Formik } from "formik";
import * as Yup from "yup";
import copy from "copy-to-clipboard";


const FormSchema = () => Yup.object({
  name: Yup.string().required("Заавал бөглөх!"),
});

const NspForm = ({ action, onSubmit, onCancel }) => {
  const [data] = React.useState({
    name: undefined,
    ...(action && action[0] === "update" ? action[1]: {})
  });

  const onCopy = (data) => {
    copy(data);
    message.success("Copied!");
  };

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={FormSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting, values }) => {

        return (
          <Form {...formItemLayout}>
            <FormItem name="name" label="Нэр" required>
              <Input name="name" placeholder="Нэр" disabled={action[0] !== "create"} />
            </FormItem>
            {action[0] !== "create" && (
              <FormItem name="clientId" label="Client ID:" required>
                <AntInput.Group compact>
                  <Input name="clientId" placeholder="Client ID" style={{ width: "calc(100% - 32px)" }} disabled />
                  <Button icon={<CopyOutlined />} onClick={() => onCopy(values.clientId)} />
                </AntInput.Group>
              </FormItem>
            )}
            {action[0] !== "create" && (
              <FormItem name="clientSecret" label="Client Secret:" required>
                <AntInput.Group compact>
                  <Input name="clientSecret" placeholder="Client Secret" style={{ width: "calc(100% - 32px)" }} disabled />
                  <Button icon={<CopyOutlined />} onClick={() => onCopy(values.clientSecret)} />
                </AntInput.Group>
              </FormItem>
            )}
            <AntForm.Item {...tailFormItemLayout}>
              <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting} disabled={action[0] !== "create"}>Хадгалах</Button>
              <Button className="button" type="default" onClick={onCancel}>Болих</Button>
            </AntForm.Item>
          </Form>
        );
      }}
    </Formik>
  );
};

export default NspForm;