import React from "react";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import PullChart from "./PullChart";

const Dashboard = () => {
  return (
    <PageContainer>
      <PageHeader title="Дашбоард" />
      <PageContent>
        <PullChart />
      </PageContent>
    </PageContainer>
  );
};

export default Dashboard;