import React from "react";
import { Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { general, auth } from "./apis";
import { PrivateRoute, PublicRoute, NavBar, Header } from "./components";
import { Login, Notfound } from "./pages";
import { UserList } from "./pages/User";
import { NspList } from "./pages/Nsp";
import { SmsList } from "./pages/Sms";
import SocketClient from "./components/SocketClient";
import Dashboard from "./pages/Dashboard";
import styled from "styled-components";

const App = ({ isMobile }) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const asyncFn = async () => {
      try {
        await Promise.all([general.init()(dispatch)]);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    asyncFn();

    if (token)
      auth.me()(dispatch);

  }, [dispatch, token]);

  React.useEffect(() => {
    dispatch({
      type   : "layout.mobile",
      payload: isMobile
    });
  }, [isMobile]);

  if (loading) return <div>loading...</div>;

  return (
    <div>
      <Switch>
        <PublicRoute path="/login" component={Login} />
        <PrivateRoute path="/">
          <Header isMobile={isMobile} />
          <Container isMobile={isMobile}>
            <div className="content">
              <div className="navbar">
                <NavBar isMobile={isMobile} />
              </div>
              <div className="wrapper">
                <Switch>
                  <Route path="/" component={Dashboard} exact />

                  <Route path="/sms" component={SmsList} exact />

                  <Route path="/settings/nsp" component={NspList} exact />
                  <Route path="/settings/user" component={UserList} exact />

                  <Route component={Notfound} />
                </Switch>
              </div>
            </div>
          </Container>
        </PrivateRoute>
      </Switch>

      <SocketClient />
    </div>
  );
};

export default App;

const Container = styled.div`
  overflow: hidden; 
  .content { 
    position: relative;
    width: 100%; 
    background: #fff;
    .navbar {
      display: flex;
      width: ${(props) => props.isMobile ? 80 : 230}px;
      border-right: 1px solid #e8e8e8;
      height: calc(100vh - 60px);
      flex-direction: column;
      position: absolute;
      left: 0;
      top: 0;
      overflow: auto;
      overflow-x: hidden; 
      background: #ffffff;
      ::-webkit-scrollbar {
        width: 2px;
        height: 2px;
      }
      ::-webkit-scrollbar-button {
        width: 2px;
        height: 2px;
      }
      ::-webkit-scrollbar-thumb {
        background: #1890ff;
        border: 0px none #ffffff;
        border-radius: 50px;
      } 
    }
    .wrapper {
      position: relative; 
      padding-left: ${(props) => props.isMobile ? 80 : 230}px;
      height: calc(100vh - 60px);
      overflow: auto;
      overflow-x: hidden; 
    }
  }
`;
