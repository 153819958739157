import React from "react";
import { Upload, Button, Space } from "antd";
import { Field, useField } from "formik";
import { connect, useSelector } from "react-redux";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";

const UploadComponent = ({ name, auth, action, onResult = () => {}, ...restProps }) => {
  const [meta] = useField(name);
  const { s3host } = useSelector((state) => state.general);
  const { token } = auth;

  const handleChange = (info, setFieldValue, setFieldTouched, setFieldError) => {
    setFieldTouched(name, true);

    if (info.file.status === "uploading") {
      setFieldError(name, "Файл хуулж байна!");
    }

    if (info.file.status === "done") {
      setFieldValue(name, info.file.response);
      setFieldError(name, undefined);

      if (onResult) {
        onResult(info.file);
      }
    }
  };

  return (
    <Field name={name}>
      {(fieldProps) => {
        const {
          field: { value },
          form: { setFieldValue, setFieldTouched, setFieldError }
        } = fieldProps;
        return (
          <Space>
            <Upload
              name="file"
              listType="text"
              // className={styles.uploader}
              showUploadList={false}
              action={action}
              headers={{
                Authorization: `Bearer ${token}`
              }}
              onChange={(info) => handleChange(info, setFieldValue, setFieldTouched, setFieldError)}
              {...restProps}
            >
              <Button style={{ border: `solid 1px ${meta.error || (meta.touched === true && !value) ? "red" : "#e8e8e8"}`, width: "max-content" }} icon={<UploadOutlined />}>
                {value ? "Файл өөрчлөх" : "Файл хуулах"}
              </Button>
            </Upload>
            {value && (
              <a href={`${s3host}${value}`} target="_blank" rel="noreferrer">
                {" "}
                <Space color="green" style={{ width: "max-content" }}>
                  {" "}
                  <DownloadOutlined /> Файл үзэх
                </Space>
              </a>
            )}
          </Space>
        );
      }}
    </Field>
  );
};

const mapStateToProps = ({ auth }) => ({
  auth
});

export default connect(mapStateToProps)(UploadComponent);
