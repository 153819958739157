import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const PublicRoute = ({ component: Component, children, ...rest }) => {
  const { token } = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!token && Component) return <Component {...props} />;

        if (!token && !Component) return children;

        return <Redirect to={{ pathname: "/" }} />;
      }}
    />
  );
};

export default PublicRoute;
