import request from "../utils/request";

export const login = (data) => request.post("/api/auth/login", data);
export const logout = () => request.get("/api/auth/logout");

export const me = () => async dispatch => {
  const res = await request.get("/api/auth/me");

  dispatch({
    type   : "auth.me",
    payload: res
  });

  return res;
};



