import { useSelector } from "react-redux";

export default () => {
  const { user } = useSelector(state => state.general);

  return {
    checkPermission: (roles = []) => {
      if (user?.role === "ADMIN")
        return true;

      if (roles?.indexOf(user?.role) !== -1)
        return true;

      return false;
    }
  };
};