const initialState = {
  user : {},
  nsp  : {},
  bots : {},
  logs : [],
  roles: [],
  env  : null
};

const general = (state = initialState, action) => {
  switch (action.type) {
    case "general.init": {
      const { env, roles, types } = action.payload;

      return {
        ...state,
        roles,
        types,
        env
      };
    }
    case "general.action":
      return {
        ...state,
        actions: action.payload
      };
    case "general.refresh":
      return {
        ...state,
        refresh: action.payload
      };
    case "general.bots":
      return {
        ...state,
        bots: action.payload.reduce((accumulator, iterator) => {
          return {
            ...accumulator,
            [iterator._id]: iterator
          };
        }, {})
      };
    case "general.bot":
      return {
        ...state,
        bots: {
          ...state.bots,
          [action.payload._id]: action.payload
        }
      };
    case "general.logs": {
      return {
        ...state,
        logs: action.payload
      };
    }
    case "general.log": {
      return {
        ...state,
        logs: [...state.logs, action.payload]
      };
    }
    case "auth.me":
      const { nsp, ...rest } = action.payload;
      return {
        ...state,
        user: rest,
        nsp : nsp
      };
    default:
      return state;
  }
};

export default general;