import request from "./request";
import moment from "moment";
import numeral from "numeral";

const dateFormat = (date) => moment(date).format("YYYY-MM-DD");
const datetimeFormat = (date) => moment(date).format("YYYY-MM-DD hh:mm");

numeral.register("locale", "mn", {
  delimiters: {
    thousands: ",",
    decimal  : ","
  },
  abbreviations: {
    thousand: "k",
    million : "m",
    billion : "b",
    trillion: "t"
  },
  currency: {
    symbol: "₮"
  }
});
numeral.locale("mn");

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  style: {
    marginTop: 20
  },
  wrapperCol: {
    xs: {
      span  : 24,
      offset: 0,
    },
    sm: {
      span  : 16,
      offset: 8,
    },
  },
};
const tugrug = val => numeral(parseFloat(val)).format("0,0$");

export { tugrug, request, dateFormat, datetimeFormat, formItemLayout, tailFormItemLayout };
