import React from "react";
import { Form, FormItem, Input, Select, Checkbox } from "formik-antd";
import { Button, Form as AntForm, Row, Col } from "antd";
import { formItemLayout, tailFormItemLayout } from "../../utils";
import { Formik } from "formik";
import { CloseOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import * as Yup from "yup";

const { Option } = Select;

const FormSchema = () => Yup.object({
  isActive: Yup.boolean().required(),
  type    : Yup.string().required("Заавал бөглөх!"),
  nickname: Yup.string().required("Заавал бөглөх!"),
  username: Yup.string().required("Заавал бөглөх!"),
  password: Yup.string().optional().nullable(),
  role    : Yup.string().required().required("Заавал бөглөх!")
});

const UserForm = ({ action, onSubmit, onCancel }) => {
  const [password, setPassword] = React.useState(false);
  const { roles, types } = useSelector(state => state.general);
  const [data] = React.useState({
    isActive: true,
    type    : undefined,
    nickname: undefined,
    username: undefined,
    password: undefined,
    role    : undefined,
    ...(action && action[0] === "update" ? action[1]: {})
  });

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={FormSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting }) => {

        return (
          <Form {...formItemLayout}>
            <FormItem name="type" label="Төрөл" required>
              <Select name="type" placeholder="Төрөл" required>
                {types.map((code, index) => {
                  return <Option key={index} value={code}>{code}</Option>;
                })}
              </Select>
            </FormItem>
            <FormItem name="username" label="Нэвтрэх нэр" required>
              <Input name="username" placeholder="Нэвтрэх нэр" />
            </FormItem>
            {password && (
              <FormItem label="Нууц үг" name="password">
                <Row gutter={12}>
                  <Col>
                    <Input name="password" placeholder="Нууц үг" />
                  </Col>
                  <Col>
                    <Button type="default" shape="circle" onClick={() => setPassword(!password)}><CloseOutlined /></Button>
                  </Col>
                </Row>
              </FormItem>
            )}
            {!password && (
              <AntForm.Item {...tailFormItemLayout} style={{ marginTop: 0 }}>
                <Button type="default" onClick={() => setPassword(!password)} block>Нууц үг солих</Button>
              </AntForm.Item>
            )}
            <FormItem name="nickname" label="Нэр" required>
              <Input name="nickname" placeholder="Нэр" />
            </FormItem>
            <FormItem name="role" label="Хандах эрх" required>
              <Select name="role" placeholder="Хандах эрх" required>
                {roles.map((code, index) => {
                  return <Option key={index} value={code}>{code}</Option>;
                })}
              </Select>
            </FormItem>
            <FormItem name="isActive" label="Идэвхтэй эсэх" required>
              <Checkbox name="isActive" />
            </FormItem>
            <AntForm.Item {...tailFormItemLayout}>
              <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>Хадгалах</Button>
              <Button className="button" type="default" onClick={onCancel}>Болих</Button>
            </AntForm.Item>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserForm;