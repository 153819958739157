import React from "react";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { Button, Modal, message as notify, Tag, Input, Space } from "antd";
import { PlusOutlined, ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { MyTable, RowAction } from "../../components";
import { user as userApi } from "../../apis";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import usePermission from "../../hooks/usePermission";
import styled from "styled-components";
import Form from "./Form";

const COLORS = {
  MAINTAINER: "red",
  DEVELOPER : "blue",
  REPORTER  : "orange",
  GUEST     : "gray",
  BACKEND   : "black",
  FRONTEND  : ""
};

const List = () => {
  const myTableRef = React.useRef();
  const dispatch = useDispatch();
  const { checkPermission } = usePermission();
  const { roles, s3host, isMobile } = useSelector(state => state.general);
  const [action, setAction] = React.useState([]);
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    query: ""
  });

  const columns = useHeader({
    COLORS,
    s3host,
    roles,
    checkPermission,
    onAction: async (key, record) => {
      switch (key) {
        case "edit" : {
          setAction(["update", record]);
          break;
        }
        case "remove" : {
          Modal.confirm({
            title     : "Баталгаажуулах",
            icon      : <ExclamationCircleOutlined />,
            content   : "Та үүнийг устгахдаа итгэлтэй байна уу!!!",
            okText    : "Устгах",
            cancelText: "Болих",
            onOk      : async () => {
              await userApi.remove(record._id);

              myTableRef.current.reload();
            },
          });
          break;
        }
        default:
      }
    },
  });

  const onCancel = () => {
    setAction([]);
  };

  const onSubmit = async (data) => {
    let res;

    console.log(action);

    if (action && action[0] === "update") {
      res = await userApi.update(action[1]?._id, data);
    } else {
      res = await userApi.create(data);
    }

    dispatch({
      type   : "general.staff",
      payload: res
    });

    notify.success("Таны хүсэлт амжилттай!");
    myTableRef.current.reload();
    onCancel();
  };

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters(state => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout)
        clearTimeout(timeout);
    };
  }, [query]);

  return (
    <PageContainer>
      <PageHeader
        title="Хэрэглэгч"
        extra={[
          checkPermission(["MAINTAINER"]) && <Button key={0} shape={isMobile && "circle"} type="primary" icon={<PlusOutlined />} onClick={() => setAction(["create"])}>{!isMobile && "Хэрэглэгч нэмэх"}</Button>
        ]}
      />

      <PageFilter>
        <Space>
          <Input
            name="query"
            value={query}
            placeholder="Хайлт хийх..."
            style={{ width: 250 }}
            onChange={e => setQuery(e.target.value)}
            prefix={<SearchOutlined />}
          />
        </Space>
      </PageFilter>

      <PageContent>
        <MyTable
          bordered={!isMobile}
          columns={columns}
          ref={myTableRef}
          filters={filters}
          loadData={userApi.list}
          rowKey={(record) => record.id} />
      </PageContent>

      <Modal
        title={action && action[0] === "create" ? "Хэрэглэгч нэмэх" : "Хэрэглэгч засах"}
        visible={action && action[0]}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        {action && action[0] && <Form onCancel={onCancel} action={action} onSubmit={onSubmit} />}
      </Modal>

    </PageContainer>
  );
};

const useHeader = ({ checkPermission, COLORS, onAction }) => {
  return [{
    title : "№",
    width : 20,
    render: (r, i, count) => {
      return (count + 1);
    },
  }, {
    title : "Нэр",
    render: (record) => {
      return `${record.nickname}`;
    }
  }, {
    title : "Нэвтрэх нэр",
    render: (record) => {
      return `${record.username}`;
    }
  }, {
    title : "Төрөл",
    render: (record) => {
      return <Tag style={{ marginBottom: 5 }} color={COLORS[record.type]}>{record.type || "-"}</Tag>;
    }
  }, {
    title : "Хандах эрх",
    render: (record) => {
      return <Tag style={{ marginBottom: 5 }} color={COLORS[record.role]}>{record.role || "-"}</Tag>;
    }
  }, {
    title : "Огноо",
    width : 200,
    render: (record) => {
      return `${moment(record.createdAt).format("YYYY.MM.DD HH:mm")}`;
    }
  }, {
    title : "Үйлдэл",
    width : 100,
    render: (record) => {
      return (
        <RowAction
          actions = {{
            edit  : checkPermission(["MAINTAINER"]) && "Засах",
            remove: checkPermission(["MAINTAINER"]) && "Устгах",
          }}
          onClick={(key) => onAction(key, record)} />
      );
    }
  }];
};

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0;
`;

export default List;