import request from "../utils/request";

export const init = () => async dispatch => {
  const res = await request.get("/api/init");

  dispatch({
    type   : "general.init",
    payload: res
  });

  return res;
};
