import React from "react";
import { Input } from "antd";
import colors from "../../colors";
import styled from "styled-components";
import { CloseOutlined } from "@ant-design/icons";

const Search = ({ values, setValues, clear }) => {
  return (
    <Wrapper>
      <a href="/#" onClick={clear} style={{ display: values === "" ? "none" : "" }} className={"icon"}>
        <CloseOutlined />
      </a>

      <Input value={values} placeholder="Хайх..." onChange={(e) => setValues(e.target.value)} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 280px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  input {
    font-size: 20px;
    color: white;
    padding-right: 35px;
    background: ${colors.primary};
    border-color: ${colors.primary};
  }
  .icon {
    position: absolute;
    z-index: 1;
    font-size: 15px;
    margin-right: 5px;
    color: #a8a9ab;

    width: 30px;
    height: 30px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.primaryDark};

    &:focus {
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${colors.primary};
    }

    &:hover {
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${colors.primary};
    }
  }
`;

export default Search;
