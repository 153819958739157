import React from "react";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { SearchOutlined, CaretDownOutlined, CheckOutlined } from "@ant-design/icons";
import { Button, Input, Space, Menu, Dropdown, Drawer, Tooltip } from "antd";
import { MyTable } from "../../components";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { sms as smsApi } from "../../apis";
import moment from "moment";
import usePermission from "../../hooks/usePermission";
import styled from "styled-components";

const List = () => {
  const history = useHistory();
  const myTableRef = React.useRef();
  const { checkPermission } = usePermission();
  const { s3host, isMobile } = useSelector((state) => state.general);
  const [action, setAction] = React.useState([]);
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    query: "",
  });

  const onCommand = (cmd, bot) => {
    switch (cmd) {
      case "resend": {
        break;
      }
      default:
    }
  };

  const moreOrLoading = (record) => {
    return (
      <Button key="dropdown" disabled={record.smsStatus === "SENT"}>
        {record.smsStatus === "SENT" ? <CheckOutlined /> : <CaretDownOutlined />}
      </Button>
    );
  };

  const moreLog = (record) => {
    const COLORS = {
      FAILED: "#FF788E",
      SENT  : "#77C454"
    };

    return (
      <Tooltip title="Лог харах">
        <Button key="active" style={{ width: 100 }} onClick={() => setAction(["logging", [record.smsStatusError || "Success!"]])}>
          <span style={{ color: COLORS[record.smsStatus] }}>{record.smsStatus}</span>
        </Button>
      </Tooltip>
    );
  };

  const onCancel = () => {
    setAction([]);
  };

  const columns = useHeader({
    checkPermission,
    onCommand,
    moreLog,
    moreOrLoading,
    history,
    s3host,
  });

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters((state) => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [query]);

  return (
    <PageContainer>
      <PageHeader title="SMS" />
      <PageFilter>
        <Space>
          <Input
            name="query"
            value={query}
            placeholder="Хайлт хийх..."
            style={{ maxWidth: "250px", width: "100%" }}
            onChange={(e) => setQuery(e.target.value)}
            prefix={<SearchOutlined />}
          />
        </Space>
      </PageFilter>
      <PageContent>
        <MyTable
          bordered={!isMobile}
          columns={columns}
          ref={myTableRef}
          filters={filters}
          loadData={smsApi.list}
          rowKey={(record) => record._id}
        />
      </PageContent>

      <DrawerLogging title={action[2] && action[2].name} width="70%" maskClosable={true} onClose={onCancel} visible={action[0] === "logging"}>
        {action[1] && (
          <div className="scrollbar">
            <div className="force-overflow">
              {action[0] === "logging" && action[1] === true && (
                <div className="spin-wrapper">
                  <Spin spinning={true} />
                </div>
              )}

              {action[1].map((value, index) => {
                return (
                  <pre key={index} className="log" style={{ color: action[2] && action[2].status.code === "failed" && "#FF788E" }}>
                    {value}
                  </pre>
                );
              })}
            </div>
          </div>
        )}
      </DrawerLogging>

    </PageContainer>
  );
};

const useHeader = ({ onCommand, moreLog, moreOrLoading }) => {
  return [
    {
      title : "№",
      width : 10,
      render: (record, index, count) => {
        return count + 1;
      },
    },
    {
      title : "Оператор",
      render: (record) => {
        return record.operator;
      },
    },
    {
      title : "Дугаар",
      render: (record) => {
        return record.phone || "-";
      },
    },
    {
      title : "Текст",
      render: (record) => {
        return record.text || "-";
      },
    },
    {
      title : "Төлөв",
      width : 200,
      render: (record) => {
        return (
          <>
            <Dropdown.Button
              {...(record.smsStatus === "SENT" && { visible: false })}
              overlay={
                <Menu className="action-group">
                  <Menu.Item key="log" onClick={() => onCommand("resend", record)}>Resend</Menu.Item>
                </Menu>
              }
              trigger="click"
              buttonsRender={() => [moreLog(record), moreOrLoading(record)]}
            />
          </>
        );
      },
    },
    {
      title : "Огноо",
      width : 100,
      render: (record) => {
        return moment(record.smsStatusDate).format("YYYY.MM.DD HH:mm");
      },
    },
  ];
};

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0;
`;

const DrawerLogging = styled(Drawer)`
  &.kubernetes {
    .ant-drawer-body {
      padding: 0 !important;
    }
    .ant-input {
      border: none !important;
      height: 100%;
    }
  }
  .log {
    line-height: 20px;
    font-size: 13px;
    font-weight: 400;
    color: #000;
    white-space: pre-wrap;
  }
  .spin-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: calc(100% - 100px);
    width: calc(100% - 15px);
    color: white;
  }
  .ant-drawer-body {
    line-height: 1;
    padding: 15px;
  }
`;

export default List;
