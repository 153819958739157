import React from "react";
import { notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";

export let socket;

export default () => {
  const dispatch = useDispatch();
  const { token } = useSelector(state => state.auth);
  const { bots } = useSelector(state => state.general);

  const openNotificationWithIcon = (data) => {
    notification[data.type]({
      message    : data.message,
      description: "",
    });
  };

  React.useEffect(() => {
    socket = io(`/?token=${token}`);

    socket.on("connection", () => {});
    socket.on("action", (action) => {

      console.log(action);

      dispatch(action);

      switch (action.type) {
        case "bot.message": {
          openNotificationWithIcon(action?.payload);
          break;
        }
        default:
      }
    });
  }, []);

  return (<></>);
};
