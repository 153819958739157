import React from "react";
import { Card, Spin } from "antd";
import styled from "styled-components";
import { Line } from "@ant-design/charts";
import { dashboard as dashboardApi } from "../../apis";
import useFetch from "../../hooks/useFetch";
import moment from "moment";
import { useSelector } from "react-redux";

const PullChart = () => {
  const [filters] = React.useState({});
  const { nsp } = useSelector(state => state.general);
  const [results, fetching] = useFetch(dashboardApi.pull, filters)([]);

  return (
    <Container isMainContainer title="Send">
      <Spin spinning={fetching}>
        <Line style={{ width: "100%" }} {...{
          data       : results?.map(r => ({ ...r, date: moment(r.date).format("YYYY.MM.DD") })),
          xField     : "date",
          yField     : "value",
          isGroup    : true,
          isStack    : true,
          groupField : "name",
          seriesField: "name",
          legend     : { position: "top-left" },
        }} height={400} forceFit={true} />
      </Spin>
    </Container>
  );
};

const Container = styled(Card)`
  border: 1px solid#e8e8e8;
  border-radius: 5px;
  box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.08);
  margin-left: 5px;
  .ant-tabs-nav{
    border-bottom: none;
  }
  .ant-card-body {
    border-radius:5px;
    padding: 15px 22px 15px 22px;
    
  }
`;

export default PullChart;